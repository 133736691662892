import React from 'react';
import ReactDOM, { render } from 'react-dom';
import 'bootstrap';
import 'jquery';
import 'leaflet';
import 'leaflet.markercluster';
import 'popper.js';

import './css/reset.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import './css/style.css';


class Page extends React.Component {
  render() {
    return (
      <div>
        <Navbar />
        <Hero />
        <Services />
        <Location />
        <Story />
        <Footer />
      </div>
    );
  }

}

function Navbar() {
  return(
    <nav className="navbar navbar-expand-lg navbar-dark fixed-top">
      <div className="container">
        <a className="navbar-brand" href="/">
          <img src="images/lapin-lomamokit-swirl-white.svg" alt="logo" />
          Lapin lomamökit & Camping
        </a>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarResponsive">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <a className="nav-link" href="#palvelut">Palvelut</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#sijainti">Sijainti</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#tarinamme">Tarinamme</a>
            </li>
          </ul>
          <a href="tel:+358407079843" className="btn btn-header rounded-pill">040 707 9843</a>
        </div>
      </div>
    </nav>  
  );
}

class Carousel extends React.Component {
  renderHeader(images) {
    return images.map((data, step) => {
      return (
        <li className={`${step === 0 ? "active" : ""}`} data-target={`#${this.props.images[0][0].split('/')[1]}`} data-slide-to={ step }></li>
      );
    });
  }

  renderImages(images) {
    return images.map((data, step) => {
      return (
        <div className={`carousel-item ${step === 0 ? "active" : ""}`}  style={{ backgroundImage: `url(${data[0]})` }}>
          <div className="carousel-caption d-none d-block">
            <h2 className="display-4">{data[1]}</h2>
            <p className="lead">{data[2]}</p>
          </div>
        </div>
      )
    });
  }

  render () {
    if (this.props.type === "hero") {
      return(
        <>
          <ol className="carousel-indicators">
            { this.renderHeader(this.props.images) }
          </ol>
          <div className="carousel-inner" role="listbox">
          { this.renderImages(this.props.images) }
          </div>
          <a className="carousel-control-prev" href={`#${this.props.images[0][0].split('/')[1]}`} role="button" data-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="sr-only">Edeltävä</span>
          </a>
          <a className="carousel-control-next" href={`#${this.props.images[0][0].split('/')[1]}`} role="button" data-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="sr-only">Seuraava</span>
          </a>
        </>
      );
    } else {
      return (
      <>
        <div id={`${this.props.images[0][0].split('/')[1]}`} className="carousel" data-ride="false" data-pause="true" role="listbox">
        <ol className="carousel-indicators">
          { this.renderHeader(this.props.images) }
        </ol>
            { this.renderImages(this.props.images) }
        </div>
        <a className="carousel-control-prev" href={`#${this.props.images[0][0].split('/')[1]}`} role="button" data-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="sr-only">Edeltävä</span>
        </a>
        <a className="carousel-control-next" href={`#${this.props.images[0][0].split('/')[1]}`} role="button" data-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="sr-only">Seuraava</span>
        </a>
      </>
      );
    };
  }
}


class Hero extends React.Component {
  render() {
    const heroSliderImages = [
      ['images/hero-slider/DSC02965.jpeg', 'Hengitä', 'Hengitä puhtainta pohjolaa.'],
      ['images/hero-slider/DSC04174.jpeg', 'Koskematon Lappi', 'Koe elämäsi seikkailu villissä luonnossa.'],
      ['images/hero-slider/DSC09821.jpeg', 'Keräilijä', 'Hillahulluus toistuu joka syksy.']
    ];
 
   return(
    <header className="text-center">
      <div id="hero-slider" className="carousel slide carousel-fade" data-ride="carousel">
        <Carousel type = 'hero' images = {heroSliderImages} />
      </div>
    </header>
   )
  }
}

function Services() {
  const boatImages = [['images/boat/DJI_0822.jpeg'], ['images/boat/DSC06606.jpeg'], ['images/boat/DSC09761.jpeg'], ['images/boat/DJI_0839.jpeg']];

  const campingImages = [['images/camping/DJI_0749.jpeg'],['images/camping/DSC09511.jpeg'],['images/camping/DSC09953.jpeg'],['images/camping/DSC09924.jpeg'],['images/camping/DSC09348.jpeg'],['images/camping/DSC09964.jpeg'],['images/camping/DSC09932.jpeg'],['images/camping/DSC00009.jpeg'],['images/camping/DSC09795.jpeg'],['images/camping/DSC00866.jpeg']];

  const cabinImages = [['images/cabin/DJI_0663.jpeg'],['images/cabin/DJI_0910.jpeg'],['images/cabin/DSC06414.jpeg'],['images/cabin/DSC09208.jpeg'],['images/cabin/DSC08999.jpeg'],['images/cabin/DSC09022.jpeg'],['images/cabin/DSC09030.jpeg'],['images/cabin/DSC09038.jpeg'],['images/cabin/DSC00434.jpeg'],['images/cabin/DSC00407.jpeg'],['images/cabin/DSC00351.jpeg'],['images/cabin/DJI_0919.jpeg'],['images/cabin/DSC00356.jpeg'],['images/cabin/DSC00182.jpeg'],['images/cabin/DSC00191.jpeg'],['images/cabin/DSC00282.jpeg'],['images/cabin/DSC00147.jpeg'],['images/cabin/DSC00165.jpeg']];

  return(
  <div className="section" id="palvelut">
    <div className="row">
      <div className="col">
        <h2 className="display-4">Palvelut</h2>
      </div>
    </div>
    <div className="row">
    <div className="col-12 col-lg-6 product-card">
        <Carousel images = { boatImages } /> 
      </div>
      <div className="col-12 col-lg-6 row">
        <div className="col-12">
          <h3>Veneet</h3>
        </div>
        <div className="col-12">
          <table className="table">
            <thead className="thead-dark">
              <tr>
                <th>Aika</th>
                <th>Hinta</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Alkavat tunnit</td>
                <td>10,00 €</td>
              </tr>
              <tr>
                <td>Vuorokausi</td>
                <td>25,00 €</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="col-12">
          <p>Vuokraamme sekä kalastukseen, että virkistykseen sopivia soutuveneitä. Alla olevaan karttaan on merkitty venevalkamat. Hintaan sisältyvät veneen lisäksi airot ja pelastusliivit.</p>
        </div>
      </div>
    </div>

    <div className="row">
      <div className="col-12 col-lg-6 row">
        <div className="col-12">
          <h3>Mökit & Talot</h3>
        </div>
        <div className="col-12">
          <table className="table">
            <thead className="thead-dark">
              <tr>
                <th>Majoituspaikka</th>
                <th>Hinta alkaen / yö</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Mökki</td>
                <td>55,00 €</td>
              </tr>
              <tr>
                <td>Mökki Saivomuotka</td>
                <td>70,00 €</td>
              </tr>
              <tr>
                <td>Talo</td>
                <td>80,00 €</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="col-12">
          <p>Mökkien varusteluun sisältyy: sähkö, keittiö perusvarusteilla (jääkaappi, kahvinkeitin, liesi, uuni, astiasto, mikro, tulipesä ja petivaatteet (tyynyt, peitot). Kaikkiin mökkeihin kuuluu oma puulämitteinen sauna ja ulkohuussi. Suomen raikkainta vettä tulee uppopumpulla kaivosta mökin kulmalle.</p>
          <p>Mökkien sijainnit löydät kartasta. Kaikki mökit sijaitsevat rauhallisilla paikoilla veden äärellä. Näitä yhdistävät komeat näkymät ja Sonkamuotkan erinomaiset lohestuspaikat.</p>
          <p>Talomme ovat mökkejä paremmin varusteltuja ja näissä on sähkösaunat, sekä edeltävien lisäksi juokseva lämmin & kylmä vesi, vesivessat ja laajemmin varusteltu keittiö (tiski-, pyykki- ja kuivauskoneet).</p>
          <p>Kaikista kohteista löytyy tulipaikallinen kota, jossa voi ihastella auringonlaskuja ja kertoa kalatarinoita.</p>
          <p>Talot ovat ympäri vuoden lämpiminä, mutta jos löytyy seikkailumieltä mökit ovat talvella lämmitettävissä.</p> <p>Enontekiöllä on Euroopan vähiten valosaastetta ja pimein yötaivas. Sonkamuotka on kuin luotu revontulien ihasteluun ja valokuvaukseen.</p>
        </div>
      </div>
      <div className="col-12 col-lg-6 product-card">
        <Carousel images = { cabinImages } /> 
      </div>
    </div>

    <div className="row">
      <div className="col-12 col-lg-6 row order-lg-2">
        <div className="col-12">
          <h3>Camping & Caravan</h3>
        </div>
        <div className="col-12">
        <table className="table">
          <thead className="thead-dark">
            <tr>
              <th>Majoituspaikka</th>
              <th>Hinta / yö</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Telttailu</td>
              <tr>
                <td>15,00 € / aikuinen</td>
              </tr>
              <tr>
                <td>7,50 € / lapsi</td>
              </tr>
            </tr>
            <tr>
              <td>Karavaanipaikka (SF-Caravan)</td>
              <td>28,00 €</td>
            </tr>
            <tr>
              <td>Karavaanipaikka</td>
              <td>30,00 €</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="col-12">
        <p>Leirintäalueemme sijaitseee E8 tien varressa Muonion ja Enontekiön rajalla. Paikoilta avautuu kaunis näköala Muonionjoelle. Meiltä löytyy sähköpistokkeelliset paikat karavaanareille ja nurmikenttä telttailijoille.</p>
        <p>Alueen palveluihin sisältyvät, saunat, suihkut, pesu- & kuivauskone, vesivessa, ulkohuussit, septitankin tyhjennysmahdollisuus, leikkikenttä, hiekkaranta, grillikota ja laituri tulipaikalla.</p>
        <p>Kaikki palvelut sisältyvät hintaan. Enintään 4 henkilöä per karavaanipaikka. Henkilöautoa isommat kulkuneuvot luokitellaan karavaaniautoiksi.</p>
        <p>Leikkipuistossa leikkiminen vain aikuisten valvonnassa ja omalla vastuulla. Pomppulinnat käytössä vain asiakkaillemme.</p>
        {/* <p style={{ color: "red" }}>Tähän palveluikonit? tai sitten esittelykuvaan</p> */}
      </div>
    </div>
    <div className="col-12 col-lg-6 product-card order-lg-1">
      <Carousel images = { campingImages } /> 
    </div>
    </div>


  </div>
  );
}

class Location extends React.Component {
  constructor(props) {
    super(props);
    this.state = {date: new Date()};
  }
  componentDidMount() {
    const iconLocation = {
      boat: "images/icons/boat.svg", 
      cabin: "images/icons/cabin.svg",
      caravan: "images/icons/caravan.svg",
      info: "images/icons/coffee.svg",
      tent: "images/icons/tent.svg"
    }
    
    let tiles = L.tileLayer(
      "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
      {
        attribution:
          'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
        maxZoom: 18,
        id: "parasol/ckepssmxf4j4l19rc4rukwjyr",
        tileSize: 512,
        zoomOffset: -1,
        accessToken:
          "pk.eyJ1IjoicGFyYXNvbCIsImEiOiJja2VwcmxtNmgya3ZyMnFucGJ5aXF1dmdhIn0.-DdaBfjk8LPSjC6ESk8P1g",
      }
    );
    
    let map = L.map("map").addLayer(tiles);
    let markers = L.markerClusterGroup();
    
    map.scrollWheelZoom.disable();
    map.on('focus', () => { map.scrollWheelZoom.enable(); });
    map.on('blur', () => { map.scrollWheelZoom.disable(); });
    
    // Grab JSON file from local server
    function loadGeoJson () {
      let xhr = new XMLHttpRequest();
      xhr.open('GET', 'json/kohteet.geojson');
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.responseType = 'json';
      xhr.onload = function() {
        if (xhr.status !== 200) return
        addMarkers(xhr.response);
      };
      xhr.send();
    }
    
    function addMarkers(kohteet) {
      const geoJSONLayer = L.geoJSON(kohteet, layerOptions);
      map.addLayer(markers.addLayer(geoJSONLayer));
    // good production values
      map.fitBounds(L.latLngBounds(markers.getBounds()).pad(10));
    }
    
    function createCustomIcon (feature, latlng) {
      let customIcon = L.icon({
        iconUrl: iconLocation[feature.properties.icon],
        iconSize:     [35, 35], // width and height of the image in pixels
        iconAnchor:   [18, 18], // point of the icon which will correspond to marker's location
        popupAnchor:  [0, 0] // point from which the popup should open relative to the iconAnchor
      })
      return L.marker(latlng, { icon: customIcon })
    }
    
    // create an options object that specifies which function will called on each feature
    let layerOptions = {
      pointToLayer: createCustomIcon,
      onEachFeature: function (feature, layer) {
        layer.bindPopup(feature.properties.name);
      }
    }
    
    loadGeoJson();
    
  }
  render() {
  return(
    <div className="section" id="sijainti">
  <div className="row">
    <div className="col-12">
      <h2 className="display-4">Sijainti</h2>
      <div id="map"></div>
    </div>
  </div>
  <div className="row">
    <div className="col-12">
      <div className="card box-shadow">
        <div className="card-header">
          <h4 className="my-0 font-weight-normal">Ota yhteyttä</h4>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-6 border-right">
              <p>Meillä ei ole toimistoa ja juoksemme töiden, lasten ja eläinten perässä ympäri Sonkamuotkaa, joten parhaiten meihin saa yhteyden soittamalla. Otamme vastaan varauksia sähköpostilla ja puhelimitse.</p>
              <p>Mikäli emme pääse paikalle niin avainpalvelu toimii Sannan putiikissa, josta voi napata mukaan eurolla kahdet munkkikahvit.</p>
            </div>
            <div className="col-md-6 text-center">
              <ul className="list-unstyled text-small">
                <li><strong>Camping -alue</strong></li>
                <li>Muoniontie 1733, Enontekiö</li>
                <li><strong>Sannan putiikki</strong></li>
                <li>Muoniontie 1623, Enontekiö</li>
              </ul>
              <ul className="list-unstyled text-small">
                <li><a href="mailto:info@lapinlomamokit.fi" className="btn btn-md btn-primary">info@lapinlomamokit.fi</a>
                </li>
                <li><a href="tel:+358407079843" className="btn btn-md btn-primary">+358 40 707 9843</a></li>
              </ul>    
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
  );
}
}

function Story() {
  return(
<div className="section" id="tarinamme">
  <div className="row">
    <div className="col-12">
      <h2 className="display-4">Tarinamme</h2>
    </div>
    <div className="col-lg-6">
      <img className="img-fluid rounded" src="images/DSC00540.jpeg" />
    </div>
    <div className="col-lg-6">
      <p>Olemme pieni suomalainen perheyritys, jota pyöritetään oman väen voimin. Alatalot ovat toimineet tällä samalla paikalla jo 50-luvulta ja nyt menossa on kolmas sukupolvi yrittäjiä. Tavallaan sitä on syntynyt yrittäjäksi. Tässä hommassa on parasta kun jokainen päivä on erillainen ja saa tehdä töitä omilla ehdoillaan.</p>
      <p>Meille tärkeää on aito Lapin tunnelma, vieraanvaraisuus ja hyvä meininki. Haluamme tarjota asiakkaillemme parhaat puolet Lapista.</p>
      <p>Sonkamuotka on sopivan kaukana etelän kiireestä ja täällä meidät pitää yötön yö. Silloin kun emme ole töissä arkea tahdittaa tanssin harrastus ja kaksi hevosta.</p>
    </div>
  </div>
</div>

  );
}


function Footer() {
  return(
<footer className="mt-auto text-center">
  <div className="inner">
  <p>Se oli siinä, tule paikan päälle ihmettelemään.</p>
  <p>&copy; { new Date().getFullYear() } <a href="/">Lapin Lomamökit</a> (2091172-5)</p>
</div>
</footer>
  );
}


ReactDOM.render(
  <Page />,
  document.getElementById('root')
  );
